import React, { useState, useEffect } from "react";
import axios from "axios";

import "./App.css";

const App = () => {
  const [advice, setAdvice] = useState("");

  useEffect(() => {
    fetchAdvice();
  }, []);

  const fetchAdvice = () => {
    axios
      .get("https://api.adviceslip.com/advice")
      .then((response) => {
        const { advice } = response.data.slip;
        setAdvice(advice);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="app">
        <div className="card">
          <h1 className="heading">{advice}</h1>
          <button className="button" onClick={fetchAdvice}>
            <span>GIVE ME ADVICE!</span>
          </button>
        </div>

        <footer className="footer">
          Made with{" "}
          <span role="img" aria-label="heart">
            ❤️
          </span>
          <a
            href="https://akib-portfolio.onrender.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Akib
          </a>
        </footer>
      </div>
    </>
  );
};

export default App;
